/*----Timeline----*/
.timeline-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 100%;
}

.timeline-event {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px;
  flex: 1; /* Hacer que los elementos ocupen el mismo espacio */
  max-width: 150px; /* Establecer un ancho máximo */
  text-align: center;
}

.event-image img {
  object-fit: cover;
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.event-header {
  font-weight: bold;
  font-size: 14px;
  margin-top: 10px;
}

.event-content {
  margin-top: 5px;
  font-size: 12px;
}

/*--------*/

/* Estilos para el acordeón */
.acordeon-container {
  max-width: 90%;
  margin: 0 auto;
}

/* Estilos para los botones del acordeón */
.acordeon-button {
  text-transform: uppercase;
  font-weight: bolder;
  border-radius: 1rem;
  background-color: #f0f0f0;
  border: none;
  color: #000000;
  padding: 12px;
  width: 100%;
  text-align: left;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.acordeon-button:hover {
  background-color: rgb(206, 206, 206);
}

/* Estilos para el contenido expandido de las tablas */
.acordeon-content {
  background-color: #ffffff;
  padding: 1px;
  border: 1px solid #ffffff;
  border-top: none;
  font-size: 14px;
}

/* Icono de flecha para indicar estado de la tabla */
.acordeon-button::after {
  content: '➕'; /* Código de la flecha (FontAwesome) */
  font-family: FontAwesome;
  float: right;
  font-size: 16px;
  margin-top: 2px;
  transition: transform 0.7s ease;
}

/* Cambiar la flecha cuando el contenido está abierto */
.acordeon-button.open::after {
  content: '➖';
  transform: rotate(360deg);
}


/*---Tabla---*/
.custom-table {
  width: 90%;
  margin: 20px auto;
  border-collapse: collapse;
  background-color: #f0f0f0;
  font-size: 12px;
}

.table-title {
  text-transform: uppercase;
  background-color: #ffffff;
  padding: 10px;
  font-weight:bold;
  text-align: left;
}

th, td {
  padding: 10px;
  text-align: left;
  width: 50%;
}

.opaque {
  opacity: 0.5;
}

.bold {
  font-weight: bold;
}
/*------*/

/*---Boton Idiomas---*/
.boton-desplegable {
  border: none;
  cursor: pointer;
  padding: 10px;
}

.contenido-desplegable {
  font-size: 14px;
  display: none;
  padding: 10px;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 120px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.795);
  z-index: 1;
  border-radius: 1rem;
}

.contenido-desplegable button {
  background-color: #f9f9f9;
  border: none;
  cursor: pointer;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  width: 100%;
  text-align: left;
}

.contenido-desplegable button:hover {
  background-color: #3b3b3b00;
}

.boton-desplegable:hover .contenido-desplegable {
  display: block;
}
/*------*/

/*---Carusel---*/
.carousel-indicators [data-bs-target]{
  background-color: rgb(255, 255, 255);
  position: relative;
  top: 6rem;
}

.carousel-control-prev {
  margin-top: 8rem;
}
.carousel-control-next { 
  margin-top: 8rem;
}

.carousel {
  position: relative;
  width: 100%;
  height: 450px;
}

.imgCarousel {
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
}

.imgCarousel img {
  object-fit: cover;
  height: 100%;
  width: 100%;
}
.imgCarousel h3 {
  position: absolute;
  top: -23rem;
  user-select: none;
  background-color: rgba(0, 0, 0, 0.589);
  padding: 0.5rem;
  border-radius: 0.5rem;
}

.imgCarousel p {
  position: absolute;
  text-align: justify;
  top: -18rem;
  margin-right: 20rem;
  user-select: none;
  background-color: rgba(0, 0, 0, 0.534);
  padding: 0.5rem;
  border-radius: 1rem;
}

.imgCarousel button {
  position: relative;
  display: inline-block;
  padding: 10px 20px;
  background: rgba(7, 7, 7, 0.411);
  color: rgb(255, 255, 255);
  font-size: 16px;
  font-weight: bolder;
  text-decoration: none;
  text-transform: uppercase;
  overflow: hidden;
  transition: .5s;
  letter-spacing: 4px;
  border: none;
  outline: none;
  border-radius: 0.5rem;
}

.imgCarousel button:hover {
  background: rgb(255, 255, 255);
  color: #000000;
  border-radius: 5px;
  box-shadow: 0 0 5px #ffffff,
    0 0 12px #ffffff,
    0 0 25px #ffffff,
    0 0 50px #ffffff;
}

.imgCarousel button span {
  position: absolute;
  display: block;
}

.imgCarousel button span:nth-child(1) {
  top: 0;
  left: -100%;
  width: 100%;
  height: 2px;
  background: linear-gradient(90deg, transparent, #ffffff);
  animation: btn-anim1 1s linear infinite;
}

.imgCarousel button span:nth-child(2) {
  top: -100%;
  right: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(180deg, transparent, #ffffff);
  animation: btn-anim2 1s linear infinite;
  animation-delay: .25s
}

.imgCarousel button span:nth-child(3) {
  bottom: 0;
  right: -100%;
  width: 100%;
  height: 2px;
  background: linear-gradient(270deg, transparent, #ffffff);
  animation: btn-anim3 1s linear infinite;
  animation-delay: .5s
}

.imgCarousel button span:nth-child(4) {
  bottom: -100%;
  left: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(360deg, transparent, #ffffff);
  animation: btn-anim4 1s linear infinite;
  animation-delay: .75s
}
/*------*/

/*---Logo---*/
.logo{
  width: 280px;
  padding-left: 40px;
}
/*------*/

/*---Imagen---*/
.imagen{
  margin-top: 2rem;
  width: 880px;
  padding-left: 40px;
}
/*------*/

/*---Icono---*/
.icono {
  width: 40px;
  height: 40px;
}

.icono2 {
  width: 40%;
  height: auto;
}

.icono3 {
  width: 100px;
  height: 100px;
}

/*------*/

/*---BFW---*/
.BFW {
  position: fixed;
  bottom: 30px;
  right: 30px;
  background-color: #25d366;
  color: white;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
  z-index: 6;
  
}
.BFW1 {
  position: fixed;
  bottom: 140px;
  right: 55px;
  width: 1px;
  height: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
  z-index: 6;
}

.BFW:hover {
  background-color: #129944;
}
/*------*/

/*---Navbar---*/
.menunavbar header{
  top: 0;
  position: fixed;
  margin-bottom: 5rem;
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10%;
  width: 100%;
  z-index: 3;
  height: 5em;
  font-size: clamp(0.66rem, 2vw, 1rem);
  background-color: #ffffff9c;
}

.menunavbar button {
  text-transform: uppercase;
  font-family: "Bebas Neue", sans-serif;
  letter-spacing: 0.1em;
  color: #000000;
  background-color: transparent;
  text-decoration: none;
  font-weight: 600;
  padding: 8px 0;
  transition: all;
  border: none;
  transition-duration: 300ms;
  border-bottom: 1px solid rgba(0, 0, 0, 0);
  cursor: pointer;
}

.menunavbar button.active {
    font-weight: bold;
    border-bottom: 1px solid #000000;
}

.menunavbar button:hover{
    color: black;
    border-bottom: 1px solid #000000;
    padding: 10px; 
}

.menunavbar div{
    list-style: none;
    gap: 25px;
    font-weight: bolder;
}

.menunavbar img{
    display: inline-block;
    padding: 10px;
    background-color: #ffffff00;
    border-radius: 1rem;
}
/*------*/

/*---Seccion de Servicio---*/
.servicio{
  margin-top: 5em;
  user-select:auto;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
  font-family: "Bebas Neue", sans-serif;
}
  
.image-servicio {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.image-servicio img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  padding: 0.3rem;
  border-radius: 3rem;
}

.text-servicio {
  user-select: none;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
}

.text-servicio p {
  font-size: 16px;
}

.text-servicio h1 {
  margin-bottom: 10px;
  position: relative;
  display: inline-block;
  padding: 10px 20px;
  background: transparent;
  color: rgb(0, 0, 0);
  font-size: 16px;
  font-weight: bolder;
  text-decoration: none;
  text-transform: uppercase;
  overflow: hidden;
  transition: .5s;
  margin-top: 10px;
  letter-spacing: 4px;
  border: none;
  outline: none;
}

.text-servicio h1:hover {
  background: rgb(255, 255, 255);
  color: #000000;
  border-radius: 5px;
  box-shadow: 0 0 5px #ffffff,
    0 0 12px #ffffff,
    0 0 25px #ffffff,
    0 0 50px #ffffff;
}

.text-servicio h1 span {
  position: absolute;
  display: block;
}

.text-servicio h1 span:nth-child(1) {
  top: 0;
  left: -100%;
  width: 100%;
  height: 2px;
  background: linear-gradient(90deg, transparent, #000000);
  animation: btn-anim1 1s linear infinite;
}

.text-servicio h1 span:nth-child(2) {
  top: -100%;
  right: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(180deg, transparent, #000000);
  animation: btn-anim2 1s linear infinite;
  animation-delay: .25s
}

.text-servicio h1 span:nth-child(3) {
  bottom: 0;
  right: -100%;
  width: 100%;
  height: 2px;
  background: linear-gradient(270deg, transparent, #000000);
  animation: btn-anim3 1s linear infinite;
  animation-delay: .5s
}

.text-servicio h1 span:nth-child(4) {
  bottom: -100%;
  left: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(360deg, transparent, #000000);
  animation: btn-anim4 1s linear infinite;
  animation-delay: .75s
}

.text-serv{
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 50px;
  padding-bottom: 50px;
}

.text-serv h2{
  height: 7rem;
  text-transform: uppercase;
  text-align: right;
  font-size: 24px;
  letter-spacing: 0.5em;
  font-weight: bold;
} 

.servicios1 {
  background-image: url(../assets/Map.svg);
  user-select: none;
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items:flex-start;
  height: 100vh;
  background-repeat: no-repeat;
  background-size: 60rem;
  background-position: center;
  font-family: "Bebas Neue", sans-serif;
}

.servicios1 h2{
  text-transform:uppercase;
  font-weight: bolder;
  margin-left: 2rem;
  padding: 10px;
}

.servicios1 li {
  letter-spacing: 0.09em;
  text-transform:uppercase;
  font-weight: bold;
  font-size: 15px;
  margin-left: 4rem;
  margin-bottom: 0.2rem;
  list-style-type: '⇛ ';
  color: rgb(0, 0, 0);
}

.servicios1 img{
  margin-left: auto;
  align-self: flex-end;
  margin-top: 19rem;  
  margin-right: 5rem;
}
/*------*/

/*---Seccion de Contacto---*/
.contacto {
  background-image: url(../assets/Contacto.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  height: 100vh;
}

.text-contacto{
  padding: 1rem;
  border-radius: 1rem;
  background-color: #00000060;
  margin-left: 5rem;
  margin-top: 6rem;
  color: rgb(255, 255, 255);
  font-size: 14px;
}

.text-contacto>a{
  color: #ffffff;
  text-decoration: none;
  padding: 8px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0);
  cursor: pointer;
  font-family: "Bebas Neue", sans-serif;
}

.text2-contacto{
  display: flex;
  align-items: center;
  font-family: "Bebas Neue", sans-serif;
}

.text2-contacto h1 {
  color: rgb(255, 255, 255);
  font-size: 14px;
  margin-left: 0.5rem;
}

.text2-contacto h1:hover {
  font-weight:bold;
  color: rgb(255, 255, 255);
  border-bottom: 1px solid rgb(255, 255, 255);
}
/*---Mapa---*/
.mapa{
  margin-top: 6rem;
  margin-left: 6rem;
  opacity: 1;
  background-color: rgba(255, 255, 255, 0.979); 
  width: 500px;
  height: 400px;
  border-radius: 5%;
  overflow: hidden;
}
/*------*/

/*---Footer---*/ 
.footer {
  user-select: none;
  display: flex;
  background: #ffffff;
  justify-content: flex-end;
  align-items: flex-end;
  min-height: 10vh;
  
}

.footer .wave {
  position: absolute;
  top: -50px;
  left: 0;
  width: 100%;
  height: 50px;
  background: url("../assets/Footer.png");
  background-size: 1000px 50px;
  opacity: 0.8;
  z-index: 1;
}

.footer.wave#wave1 {
  z-index: 1;
  opacity: 0.6;
  bottom: 0;
  animation: animateWaves 20s linear infinite;
}

.footer .wave#wave2 {
  z-index: 2;
  opacity: 0.5;
  bottom:3px;
  animation: animate 20s linear infinite !important;
}

.footer .wave#wave3 {
  z-index: 3;
  opacity: 0.2;
  bottom: 5px;
  animation: animateWaves 15s linear infinite;
}

.footer .wave#wave4 {
  z-index: 4;
  opacity: 1;
  bottom: 5px;
  animation: animate 15s linear infinite;
}

.footer .footer1 {
  margin-top: 4rem;
  position: relative;
  width: 100%;
  background: #142ca4;
  min-height: 5px;
  padding: 10px 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  
}

.icono3 {
  transition: transform 0.5s ease;
}

.zoomed {
  transform: scale(1.1);
}


.footer .footer1 p {
  color: #fff;
  margin: 1px 0 10px 0;
  font-size: 10px;
  font-weight: 100;
  margin-left: 5rem;
  margin-right: 5rem;
}
/*------*/ 

/*---Rastreo---*/ 
.rastreo {
  user-select: none;
  display: flex;
  font-family: sans-serif;
  height: 80vh; 
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.541) 60%, rgb(255, 255, 255) 100%), url(../assets/rastreo.jpg);
  background-size: cover;
  background-position: bottom;
  background-repeat: no-repeat;
}
/* Estilos para el título */
.rastreo .titulo {
  position: absolute;
  top: 25%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center; 
  width: 100%;
}
  
.rastreo .titulo h2 {
  letter-spacing: 0.1em;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  font-size: 2.5rem;
  font-family: "Bebas Neue", sans-serif;
  
  font-weight: bolder;
  margin: 0;
}
  
/* Estilos para la información */
.rastreo .info {
  display: table-column;
  position: absolute;
  text-align: center;
  top: 35%; 
  left: 50%;
  transform: translate(-50%, -50%);
  color: #ffffff;
  font-size: 18px;
  font-family: "Bebas Neue", sans-serif;
  font-weight: bold;
  width: 100%;
}
  
/* Estilos para la barra de búsqueda */
.search-bar {
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 90%;
  max-width: 400px;
  margin: 1 auto;
}
.search-bar2 {
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 90%;
  max-width: 500px;
  margin: 1 auto;
}

.search-bar select {
  padding: 8px 16px;
  flex: 1;
  width: 20px;
  border: 1px solid #ffffff;
  border-radius: 19px;
  margin-right: 8px;
  background-color: #ffffff;
  color: #333333;
}

.search-bar select option {
  background-color: #ffffff;
  color: #333333;
}

.cargando {
  position: relative;
  height: 15rem;
  top: 5rem;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 90%;
  max-width: 500px;
  margin: 1 auto;
}
  
.cargando p{
  text-transform: uppercase;
  font-size: 18px;
  text-align:center;   
  font-family: sans-serif;
  color: #000000;
}

.search-input {
  padding: 8px 16px;
  flex: 1;
  max-width: 600px;
  border: 1px solid #ffffff;
  border-radius: 19px;
  margin-right: 8px;
}
  
.search-button {
  padding: 8px 16px;
  background-color: #1885b1;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.search-button:hover {
  background-color: #0056b3;
}

/*Tabla de datos*/
.print-datos {
  display: grid;
  height: 20rem;
  margin-top: 30rem;
  margin-left: 1rem;
  margin-right: 1rem;
  text-align:left;   
  font-family: sans-serif;
  color: #ffffff;
}

.print-datos table {
  margin-bottom: 0.5rem;
  list-style-type: none;
  padding: 0;
}

.print-datos table thead {
  margin-bottom: 1rem;
}

.print-datos strong {
  font-weight: bold;
}

.print-datos tr strong::after {
  margin-right: 5px;
}

.print-datos tr span {
  margin-left: 1px;
}
/*------*/ 

/*---Tabla---*/ 
.tabla {
  background-color: #575757ad;
  border-collapse: collapse;
  width: 100%;
  empty-cells: show; 
}

.tabla caption {
  border: 2px solid black;
  padding: 8px;
  text-align:center;
  width: 10rem; 
  color: white;
  font-family: sans-serif;
}

.tabla th, .tabla td {
  border: 2px solid black;
  padding: 8px;
}
.tabla tr:nth-child(2n) {
  background-color: #797979a2;
}
/*------*/ 

/*---Responsive---*/ 
@media (max-width: 768px) {
  .carousel-indicators [data-bs-target] {
    top: -5rem;
  }
  .carousel {
    height: 100vh;
  }
  .imgCarousel {
    height: 90vh;
  }
  .imgCarousel h3 {
    top: -20rem;
    font-size: 18px;
    padding: 0.3rem;
  }
  .imgCarousel p {
    top: -17rem;
    margin-right: 1rem;
    font-size: 13px;
    padding: 0.3rem;
  }
  .logo{
    width: 250px;
  }
  .icono {
    width: 30px;
    height: 30px;
  }
  .menunavbar header {
    position:absolute;
    flex-direction: column;
    height: auto;
    padding: 1em;
    font-size: 1rem;
  }
  .menunavbar a {
    padding: 5px 0;
  }
  .menunavbar div{
    list-style: none;
    gap: 25px;
    font-weight: bolder;
  }
  .servicios1 {
    background-size: 100%; /* Adjust the background size for mobile */
    height: auto;
    padding: 1rem;
    text-align: left;
    margin-top: 0;
  }
  .servicios1 h2 {
    font-size: 18px;
    margin-left: 0;
    padding: 10px;
  }
  .servicios1 li {
    font-size: 8px;
    margin-left: 0;
    margin-bottom: 0.5rem;
    background-color: #0000007a;
    color: white;
    border-radius: 0.2rem;
  }
  .servicios1 img {
    margin-left: 0;
    align-self: center;
    margin-top: 1rem;
    width: 200px;
    margin-right: 0;
  }
  .servicio {
    margin-top: 20rem;
    flex-direction: column;
    height: auto;
    padding: 0;
    text-align: left;
  }
  .text-servicio h1 {
    font-size: 26px;
  }
  .text-servicio p {
    font-size: 16px;
  }
  .text-serv {
    padding: 0.5rem;
  }
  .text-serv h2 {
    height: auto;
    text-align: center;
    font-size: 18px;
  }
  .image-servicio img {
    width: 100%;
  }
  .contacto {
    margin-top: 20rem;
    background-position: right;
    height: 150vh;
    flex-direction: column;
    align-items: center;
    padding: 0.5rem;
    margin-bottom: 1rem;
  }
  .text-contacto {
    margin: 0.5rem;
    text-align: left;
  }
  .text-contacto>a {
    text-align: left;
    padding: 6px 0;
    font-size: 16px;
  }
  .text2-contacto {
    text-align: left;
    flex-direction: flex;
    align-items: center;
    font-size: 10px;
    margin: 1rem;
  }
  .text2-contacto h1 {
    margin-left: 1rem;
    text-align: left;
    font-size: 16px;
  }
  .mapa {
    margin: 0.1rem;
    width: 90%;
    height: 400px;
  }
  .rastreo {
    height: 100vh;
  }
  .rastreo .titulo {
    top: 40%;
    left: 50%;
  }
  .rastreo .titulo h2 {
    font-size: 30px;
  }
  .rastreo .info {
    top: 55%;
    font-size: 18px;
  }
  .search-bar {
    top: 65%;
    width: 90%;
    max-width: 400px; 
  }
  .search-bar2 {
    top: 75%; /* Ajustar la posición vertical */
    width: 90%;
    max-width: 400px; /* Ajustar el ancho máximo */
  }
  .search-input {
    max-width: 100%;
    margin: 8px 0;
  }
  .search-button {
    max-width: 100%;
  }
  .print-datos {
    margin: 1rem;
    margin-top: 25rem;
  }
  .tracking-container {
    margin-top: 5rem;
    height: auto;
  }
  .tracking-step {
    flex-direction: column;
  }
  .tabla {
    margin-top: 3rem;
    font-size: 12px;
  }
  .tabla th,
  .tabla td {
    padding: 6px;
  }
  .footer .footer1 p {
    margin: 15px 0 15px 0;
    font-size: 0.5rem;
    font-weight: 400;
  }
}

@media (min-width: 1367px) {
  .carousel-indicators [data-bs-target] {
    top: 20rem;
  }
  .menunavbar header {
    height: 5em;
    font-size: 1.3rem; 
  }
  .imgCarousel h3 {
    top: -30rem;
    font-size: 34px;
  }
  .imgCarousel p {
    top: -25rem;
    font-size: 24px;
  }
  .menunavbar a {
    padding: 12px 0;
  }
  .carrusel {
    height: 80vh;
  }
  .carrusel > div {
    height: 50vh; 
    padding-top: 15%; 
    font-size: 1.2rem; 
  }
  .carrusel > div > h2 {
    font-size: clamp(1.2rem, 2vw, 1.5rem); 
  }
  .text-servicio h1 {
    font-size: 26px;
  }
  .text-servicio p {
    font-size: 18px;
  }
  .text-contacto {
    font-size: 20px; /* Ajusta el tamaño de fuente según tus preferencias */
    margin-left: 5rem; /* Ajusta el margen izquierdo según tus preferencias */
    margin-top: 8rem; /* Ajusta el margen superior según tus preferencias */
  }
}
/*------*/

/*---Animaciones---*/ 
@keyframes animateWaves {
  0% {
    background-position-x: 1000px;
  }
  100% {
    background-positon-x: 0px;
  }
}

@keyframes animate {
  0% {
    background-position-x: -1000px;
  }
  100% {
    background-positon-x: 0px;
  }
}

@keyframes btn-anim1 {
  0% {
    left: -100%;
  }

  50%,
  100% {
    left: 100%;
  }
}

@keyframes btn-anim2 {
  0% {
    top: -100%;
  }

  50%,
  100% {
    top: 100%;
  }
}

@keyframes btn-anim3 {
  0% {
    right: -100%;
  }

  50%,
  100% {
    right: 100%;
  }
}

@keyframes btn-anim4 {
  0% {
    bottom: -100%;
  }

  50%,
  100% {
    bottom: 100%;
  }
}
/*------*/ 