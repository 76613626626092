.timeline-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 100%;
}

.timeline-event {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px;
}
.event-info {
  text-align: center;
}

.event-image img {
  object-fit: cover;
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.event-header {
  font-weight: bold;
  font-size: 14px;
  margin-top: 10px;
}

.event-content {
  margin-top: 5px;
  font-size: 12px;
}
